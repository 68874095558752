import { formatCloudinarySize, formatCloudinaryImagePath } from '@packages/utils/cloudinary.js'
import { findClosestSizeStep } from '@packages/utils/images.js'

const DPRS = [1, 2, 3];

export const preloadImage = (src, srcset = null, sizes = null) => new Promise((resolve, reject) => {
  if (!process.browser) {
    resolve()
    return
  }

  const image = new Image()
  image.onload = resolve
  image.onerror = reject
  image.src = src

  if (srcset) {
    image.srcset = srcset
  }

  if (sizes) {
    image.sizes = sizes
  }
})

export const preloadResource = ({ src, srcset, sizes, as, ...attributes }, id) => {
  const linkElementId = id ?? src
  const existingLinkElement = document.getElementById(linkElementId)

  const linkElem = existingLinkElement || document.createElement('link')
  if (onload) {
    linkElem.onload = onload()
  }

  if (src) {
    linkElem.setAttribute('href', src)
  }

  if (attributes) {
    Object.entries(attributes).forEach(([attribute, value]) => linkElem.setAttribute(attribute, value))
  }

  if (as === 'image') {
    if (srcset) {
      linkElem.setAttribute('imagesrcset', srcset)
    }

    if (sizes) {
      linkElem.setAttribute('imagesizes', sizes)
    }

    linkElem.setAttribute('as', as)
    linkElem.setAttribute('rel', 'preload')
  } else if (as === 'css') {
    linkElem.setAttribute('rel', 'stylesheet')
  }

  linkElem.setAttribute('id', linkElementId)

  if (!existingLinkElement) {
    document.head.appendChild(linkElem)
  }
}

/**
 * Returns preload objects ready to be spread into head
 * Defaults to closest size step in entries missing image width
 * @prop {string} image
 * @prop {Array<{ imageHeight: number; imageWidth: number; screenWidth: number }} sizes
 * @prop {{ gravity: string; fetchPriority: 'low' | 'high' | 'auto' }} options
 * @returns {Array<Object>} Prelad link object array
 */
export const createCloudinaryImagePreloadObjects = (image, sizes, options) =>
  sizes.map(({ containerWidth, containerHeight, screenWidth }, sizeIndex) => {
    return DPRS.map((dpr, dprIndex) => ({
      rel: 'preload',
      as: 'image',
      fetchpriority: options.fetchPriority,
      href: formatCloudinaryImagePath(image, {
        transformation: {
          custom: `h_${findClosestSizeStep({ h: containerHeight }).h},w_${findClosestSizeStep({ w: containerWidth ?? screenWidth }).w},${formatCloudinarySize(null, null, options.gravity, `${dpr}.0`)}`
        }
      }),
      media: (() => {
        let appendString
        if (dprIndex === 0) {
          appendString = `and (max-resolution: ${dpr}dppx)`
        } else if (dprIndex === DPRS.length - 1) {
          appendString = `and (min-resolution: ${DPRS[dprIndex - 1] + 0.01}dppx)`
        } else {
          appendString = `and (min-resolution: ${DPRS[dprIndex - 1] + 0.01}dppx) and (max-resolution: ${dpr}dppx)`
        }

        if (sizeIndex === 0) {
          return `(max-width: ${screenWidth}px) ${appendString}`
        }
        if (sizeIndex === sizes.length - 1) {
          return `(min-width: ${sizes[sizeIndex - 1].screenWidth + 1}px) ${appendString}`
        }
        return `(min-width: ${sizes[sizeIndex - 1].screenWidth + 1}px) and (max-width: ${screenWidth}px) ${appendString}`
      })()
    }))
  }).flat()
