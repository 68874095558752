export const IGNORE_ERROR_TYPES = [
  'Post not found:',
  'Page not found:',
  'Not found:',
  'Hydration completed but contains mismatches',
  'TypeMismatchError',
  'isDestroyed',
  'ResizeObserver',
  'webkitExitFullScreen',
  'ReportingObserver',
  'HierarchyRequestError',
  'The operation is insecure',
  'in strict mode',
  'Blocked a frame with origin',
  'Ignored attempt to cancel',
  'instantSearchSDKJSBridgeClearHighlight',
  'change_ua',
  'ibFindAllVideos',
  'loadDownloads',
  'processRandomSelector',
  'Illegal invocation',
  'global code',
  'Decoding failed',
  'Failed to fetch',
  'PaymentAutofillConfig',
  'Navigation cancelled',
  'messagehandlers',
  'Non-Error promise rejection captured',
  'Load failed',
  'replaceState',
  'webkit.messageHandlers',
  'Aborted',
  'AbortError',
  'captured as promise rejection',
  'Failed to initialize WebGL',
  'Unable to preload CSS for',
  '[GET] "/_nuxt/builds/meta/',
  'Unreachable hosts - your application id may be incorrect.',
  "IFrameMessageHandler",
  'The operation was aborted',
  "Cannot read properties of undefined (reading 'default')",
  'Request signal is aborted',
  'The user aborted a request',
  'The operation was aborted due to timeout',
  'No error message'
]
