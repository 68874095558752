
export const useConfigStore = defineStore('config', {
  state: () => {
    const { public: { baseURL, apiURL, images: imagesConfig } } = useRuntimeConfig()

    return { baseURL, apiURL, imagesConfig }
  },
  getters: {},
  actions: {}
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCalendarStore, import.meta.hot))
}
