import { joinURL } from 'ufo'
import defu from 'defu'

import localStorageKeys from '@layers/web/constants/local-storage-keys'
import { API_DISALLOWED_FILETYPES } from '@layers/web/constants/api-fetch'



/**
 * @param {string} request Api path without env baseURL
 * @param {$fetch options} opts throwOnFail Default true, if false, return error object instead of throwing | externalUrl Default false if true, request will be made to external URL use full URL as request | addToken Default false, if true, add token to body
 * @returns {
 *   ...fetch response,
 *   data: data.json
 * }
 */
export const apiFetch = async (request, opts = {}) => {
  // Prevent catch-all routes from trying to fetch files from API
  const { throwOnFail, externalUrl, timeout } = {
    throwOnFail: true,
    externalUrl: false,
    timeout: 60 * 1000,
    ...opts
  }

  if (API_DISALLOWED_FILETYPES.some(filetype => request.endsWith(filetype))) {
    if (throwOnFail) {
      throw new Error('Not found: ' + request)
    }

    return {
      data: {},
      error: {}
    }
  }

  const { apiURL, baseURL } = useConfigStore()
  const rootStore = useRootStore()

  const fetchOptions = {
    headers: {},
    timeout: timeout,
  }

  const c = rootStore.rolfsCookie
  if (c) {
    fetchOptions.headers['X-ROLFS-RF'] = c
  }

  if (!process.server) {
    fetchOptions.headers['X-HREF'] = location.href
  }

  if (rootStore.agentCookie && rootStore.agentCookie.code) {
    fetchOptions.headers['X-ROLFS-A'] = rootStore.agentCookie.code
    fetchOptions.headers['X-ROLFS-ATIME'] = rootStore.agentCookie.currentDate
  }

  // Check for method and addToken in opts
  if (opts.method === 'post' && opts.addToken === true) {
    const token = localStorage.getItem(localStorageKeys.auth.token)

    if (token) {
      fetchOptions.body = {
        token: token
      }

    }
  }

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(externalUrl ? `[apiFetch] Making external request to: ${request}` :`[apiFetch] Making request to: ${baseURL}${request}`)
  }

  const mergedFetchOptions = defu(opts, fetchOptions)

  const url = externalUrl
  ? request
  : process.browser
      ? `${baseURL}${request}` // PROXY
      : joinURL(apiURL, baseURL) + request // API URI

  let response = null
  try {
    response = await $fetch.raw(url, mergedFetchOptions)
  } catch (error) {
    if (process.NODE_ENV === 'development') {
      console.error('[apiFetch] Request failed: ', error)
    }

    if (throwOnFail) {
      throw error
    } else {
      return { error }
    }
  }

  if (response.headers.get('x-rolfs-rf') && !rootStore.rolfsCookie) {
    const c = response.headers.get('x-rolfs-rf')

    try {
      rootStore.SET_ROLFSCOOKIE(c)
    } catch (e) {
      // Cannot set headers after they are sent to the client
    }
  }

  return {
    ...response,
    data: response._data,
  }
}