
export const useTripStore = defineStore('trip', {
  state: () => ({
    categoryStart: [],
    calendar: {},
    itinerary: null,
    reviewsForTriptype: [],
    suggestedTriptypes: [],
    hotelsTriptypes: [],
    cruiseshipsTriptypes: [],
    faqTriptypes: [],
    filmsTriptypes: [],
    ratingsChart: {},
    calendarPage: 0,
    trip: {},
    tripPath: '',
    tripTab: 'details',
    tripTabVisited: {
      details: false,
      calendar: false,
      reviews: false,
      hotels: false,
      ships: false,
      faq: false,
      films: false
    },
    upcomingTriplist: [],
    bannerTrip: {},
    movieAvailable: true
  }),
  getters: {
    //getters here
  },
  actions: {
    //old mutations
    SET_CATEGORY_START (data) {
      this.categoryStart = data
    },

    SET_CALENDAR (data) {
      this.calendar = data
    },

    SET_ITINERARY (data) {
      this.itinerary = data
    },

    SET_REVIEWS_FOR_TRIP_TYPES (data) {
      this.reviewsForTriptype = data
    },

    SET_FAQ_FOR_TRIP_TYPES (faq) {
      this.faqTriptypes = faq
    },

    SET_FILMS_FOR_TRIP_TYPES (films) {
      this.filmsTriptypes = films
    },

    SET_SUGGESTED_TRIP_TYPES (data) {
      this.suggestedTriptypes = data
    },

    SET_RATINGS_CHART (data) {
      this.ratingsChart = data
    },

    SET_TRIP (trip) {
      this.trip = trip
    },

    SET_TRIP_PATH (path) {
      this.tripPath = path
    },

    SET_TRIP_TAB (tripTab) {
      this.tripTab = tripTab
    },

    SET_UPCOMING_TRIP_LIST (data) {
      this.upcomingTriplist = data
    },

    SET_HOTELS_FOR_TRIP_TYPES (hotels) {
      this.hotelsTriptypes = hotels
    },

    SET_CRUISESHIPS_FOR_TRIP_TYPES (cruiseships) {
      this.cruiseshipsTriptypes = cruiseships
    },

    SET_BANNER_TRIP (trip) {
      this.bannerTrip = trip
    },

    SET_MOVIE_AVAILABLE (available) {
      this.movieAvailable = available
    },

    SET_TRIP_VISITED (payload) {
      const { tabName, value } = payload
      this.tripTabVisited[tabName] = value
    },

    SET_CALENDAR_PAGE (page) {
      this.calendarPage = page
    },

    INIT_TRIP_VISITED () {
      this.tripTabVisited = { ...this.tripTabVisited }
      this.calendarPage = 0
    },
  // old actions
    async fetchCategoryStart () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { $sentryCaptureMessage } = useNuxtApp()

      const { data: result } = await apiFetch(`/${locale}/category/start`)

      if (!result.trips || result.trips.length === 0) {
        $sentryCaptureMessage('No trips returned for start page.', {
          contexts: {
            details: {
              result
            }
          },
          tags: {
            type: 'UX'
          }
        })
      }

      this.SET_CATEGORY_START(result.trips)

      return result.trips
    },

    async fetchCategory (categoryQuery) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/category/${categoryQuery}`)

      return result
    },

    async fetchCalendar (paramsObj) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const urls = localeStore.localeURLs
      let params = ''
      let firstIteration = true
      for (const property in paramsObj) {
        params += firstIteration ? '?' : '&'
        params += `${property}=${paramsObj[property]}`
        if (firstIteration) {
          firstIteration = false
        }
      }
      const { data: result } = await apiFetch(`/${locale}/calendar${params}`)

      // !TODO do this in backend instead
      if (result.departures?.length) {
        result.departures = result.departures.map(departure => ({
          ...departure,
          tourleaderUrl: `${urls.tourleader}/${departure.tourleader_nid}`
        }))
      }

      if (!paramsObj.dontCommit) {
        this.SET_CALENDAR(result)
      }

      return result
    },

    async fetchItinerary (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      if (!triptypeId) {
        return false
      }

      const { data: result } = await apiFetch(`/${locale}/itinerary/${triptypeId}`)

      return result
    },

    async fetchFaq (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      if (!triptypeId) {
        return false
      }
      const { data: result } = await apiFetch(`/${locale}/internal_faq/${triptypeId}`)

      return result
    },

    async fetchReviewsForTriptype (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/reviews-for-triptype/${triptypeId}`, {
          progress: false
        })

        this.SET_REVIEWS_FOR_TRIP_TYPES(result)
        return result
      } catch (e) {
        //
      }
    },

    async fetchBedplacesForTriptype (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: bedplaces } = await apiFetch(`/${locale}/hotels-for-triptype2/${triptypeId}`)
      this.SET_HOTELS_FOR_TRIP_TYPES(bedplaces?.hotels)
      this.SET_CRUISESHIPS_FOR_TRIP_TYPES(bedplaces?.cruiseships)

      return bedplaces
    },

    async fetchFaqForTriptype (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/faq/${triptypeId}`)

      this.SET_FAQ_FOR_TRIP_TYPES(result)

      return result
    },

    async fetchFilmsForTriptype (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/films/${triptypeId}`)

      this.SET_FILMS_FOR_TRIP_TYPES(result)

      return result
    },

    async fetchSuggestedTriptypes (triptypeId, number = 5) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/triptypes-suggested/${triptypeId}/${number}`)

      this.SET_SUGGESTED_TRIP_TYPES(result)

      return result
    },

    async fetchRatingsChart (triptypeId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/ratingschart/${triptypeId}`)

      this.SET_RATINGS_CHART(result)
    },

    async fetchUpcomingTriplist (category) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/upcoming-triplist/${category}`)

      this.SET_UPCOMING_TRIP_LIST(result)
    },

    async fetchTripType (paramsObj) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      let params = ''
      let firstIteration = true
      for (const property in paramsObj) {
        params += firstIteration ? '?' : '&'
        params += `${property}=${paramsObj[property]}`
        if (firstIteration) {
          firstIteration = false
        }
      }

      const { data: result } = await apiFetch(`/${locale}/triptype${params}`)

      this.SET_BANNER_TRIP(result)
      return result
    },

    async registerTripReview (payload) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const { data: result } = await apiFetch(`/${locale}/review-register`, {
        method: 'post',
        body: payload
      })

      return result
    },

    async fetchTravelerPicturesByTriptype(triptypeId, size = '200x200', fit = '') {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: result } = await apiFetch(`/${locale}/user_photos/list/${triptypeId}`)

      const amazonUrl = 'https://rolfsbuss-app-shared-images.s3.eu-north-1.amazonaws.com/'
      const thumbnailUrl = `https://images.rolfsbuss.se/thumbnails/${size}${fit}/`

      result.forEach(item => {
        if (item.uri) {
          item.thumbnail = item.uri.replace(amazonUrl, thumbnailUrl)
        }
      })

      return result
    }
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTripStore, import.meta.hot))
}