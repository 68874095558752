// Provide reusable utilities for reactive properties dependent on screen width
// Migrated
<template lang="pug">
div
  slot(v-bind="slotProps")
</template>

<script>
import _throttle from 'lodash.throttle'

import { SCREEN_WIDTH_SIZES } from '@packages/screen/constants/sizes.js'

export default defineNuxtComponent({
  name: 'ScreenWidthProvider',

  props: {
    throttle: {
      type: [Number, Boolean],
      default: 200
    }
  },

  emits: ['update'],

  data () {
    return {
      windowWidth: null,
      listener: null
    }
  },

  computed: {
    xs () {
      return this.windowWidth <= SCREEN_WIDTH_SIZES.xs
    },

    sm () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.xs && this.windowWidth <= SCREEN_WIDTH_SIZES.sm
    },

    md () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.sm && this.windowWidth <= SCREEN_WIDTH_SIZES.md
    },

    lg () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.md && this.windowWidth <= SCREEN_WIDTH_SIZES.lg
    },

    xl () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.lg && this.windowWidth <= SCREEN_WIDTH_SIZES.xl
    },

    mxl () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.xl && this.windowWidth <= SCREEN_WIDTH_SIZES.mxl
    },

    xxl () {
      return this.windowWidth > SCREEN_WIDTH_SIZES.mxl && this.windowWidth <= SCREEN_WIDTH_SIZES.xxl
    },

    slotProps () {
      return {
        width: this.windowWidth,
        xs: this.xs,
        sm: this.sm,
        md: this.md,
        lg: this.lg,
        xl: this.xl,
        mxl: this.mxl,
        xxl: this.xxl,
        isWider: this.isWider,
        isNarrower: this.isNarrower,
        isBetween: this.isBetween
      }
    }
  },

  mounted () {
    this.updateWidth()
    this.listener = this.throttle ? _throttle(this.updateWidth, this.throttle) : this.updateWidth
    window.addEventListener('resize', this.listener)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.listener)
  },

  methods: {
    updateWidth () {
      if (window.innerWidth === this.windowWidth) {
        return
      }
      this.windowWidth = window.innerWidth
      this.$emit('update', this.windowWidth)
    },

    isWider (size, offset = 0) {
      return typeof size === 'number' ? this.windowWidth + offset >= size : this.windowWidth + offset > SCREEN_WIDTH_SIZES[size]
    },

    isNarrower (size, offset = 0) {
      return typeof size === 'number' ? this.windowWidth + offset < size : this.windowWidth + offset <= SCREEN_WIDTH_SIZES[size]
    },

    isBetween (size1, size2) {
      let val1 = typeof size1 === 'number' ? size1 : SCREEN_WIDTH_SIZES[size1]
      let val2 = typeof size2 === 'number' ? size2 : SCREEN_WIDTH_SIZES[size2]

      if (val1 > val2) {
        [val1, val2] = [val2, val1]
      }

      return this.isWider(val1) && this.isNarrower(val2)
    }
  },
})
</script>
