
export const localeEnv = (envKey: string) => (
  (process.env.locale && process.env[`${envKey}_${process.env.locale.toUpperCase()}`]) ??
  process.env[envKey]
)

export const localeToLangInfo = (locale: string) => {
  switch (locale) {
    case 'da':
      return {
        code: locale,
        lang: locale,
        name: 'Dansk',
        iso: 'da-DK'
      }
    case 'no':
      return {
        code: locale,
        lang: 'nb',
        name: 'Norsk',
        iso: 'nb-NO'
      }
    case 'en':
      return {
        code: locale,
        lang: locale,
        name: 'English',
        iso: 'en-GB'
      }
    case 'pl':
      return {
        code: locale,
        lang: locale,
        name: 'Polski',
        iso: 'pl-PL'
      }
    case 'zh-cn':
      return {
        code: locale,
        lang: locale,
        name: '中國',
        iso: 'zh-CN'
      }
    default:
      return {
        code: locale,
        lang: 'sv',
        name: 'Svenska',
        iso: 'sv-SE'
      }
  }
}

export const localeToLang = (locale: string) => localeToLangInfo(locale).lang
